import React from 'react'

import { Layout } from '../components/layout/layout'
import { IntroLimited } from '../components/intro-limited/intro-limited'
import { AboutLimited } from '../components/about-limited/about-limited'
import { Footer } from '../components/footer/footer'

const PrivacyPolicyPage = () => (
  <Layout>
    <div id="top">
      <IntroLimited />

      <AboutLimited />

      <Footer />
    </div>
  </Layout>
)

export default PrivacyPolicyPage
