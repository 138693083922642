import React, { Component } from 'react'

import './read-more-button.scss'

export class ReadMore extends Component {
  render() {
    const { url, title } = this.props

    return (
      <div className="read-more-wrapper">
        <a href={url}>{title ? title : 'Read more'}</a>
      </div>
    )
  }
}
